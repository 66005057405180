import { camelCase } from 'lodash'
import { ApolloClient } from '@apollo/client'
import { ApolloContext } from '@/services/types/Gql'
import {
  ThreadUpdate,
  ThreadUpdateResponse
} from '@/services/types/Thread'
import threadArchive from '@/graphql/threads/threadArchive.gql'
import threadUnarchive from '@/graphql/threads/threadUnarchive.gql'
import threadTranslate from '@/graphql/threads/threadTranslate.gql'
import showThreadTranslateOutgoing from '@/graphql/threads/showThreadTranslateOutgoing.gql'
import threadReverseTranslation from '@/graphql/threads/threadReverseTranslation.gql'
import threadUpdate from '@/graphql/threads/threadUpdate.gql'
import getAvailableLanguages from '@/graphql/threads/getAvailableLanguages.gql'
import {
  SmsThreadTranslateOutgoing,
  SmsThreadReverseTranslation,
  SmsThreadTranslate,
  Maybe,
  AvailableLanguage
} from '@/types/generated'

interface IThreadGql {
  $apollo: ApolloClient<any>
  archive: (tenantId: string, threadId: string) => Promise<Maybe<ThreadUpdateResponse>>
  unarchive: (tenantId: string, threadId: string) => Promise<Maybe<ThreadUpdateResponse>>
  toggleTranslation: (threadId: string, enable: boolean) => Promise<Maybe<SmsThreadTranslate>>
  previewTranslation: (body: string, threadId: string) => Promise<Maybe<SmsThreadTranslateOutgoing>>
  previewReverseTranslation: (threadId: string, translatedText: string) => Promise<Maybe<SmsThreadReverseTranslation>>
  changeTranslationLanguage: (threadId: string, language: string) => Promise<Maybe<ThreadUpdate>>
  updateThread: (threadId: string, data: Record<string, any>) => Promise<Maybe<ThreadUpdate>>
  getAvailableLanguages: () => Promise<Maybe<AvailableLanguage>[] | null>
}

export default class ThreadGql implements IThreadGql {
  $apollo: ApolloClient<any>

  constructor (context: ApolloContext) {
    this.$apollo = context.$apollo
  }

  archive (tenantId: string, threadId: string) : Promise<Maybe<ThreadUpdateResponse>> {
    return this.$apollo.mutate({
      mutation: threadArchive,
      variables: {
        tenantId,
        threadId
      }
    }).then(result => result.data)
  }

  unarchive (tenantId: string, threadId: string): Promise<Maybe<ThreadUpdateResponse>> {
    return this.$apollo.mutate({
      mutation: threadUnarchive,
      variables: {
        tenantId,
        threadId
      }
    }).then(result => result.data)
  }

  toggleTranslation (threadId: string, enable: boolean): Promise<Maybe<SmsThreadTranslate>> {
    return this.$apollo.mutate({
      mutation: threadTranslate,
      variables: { threadId, enable }
    }).then(result => result.data?.threadTranslate)
  }

  previewTranslation (body: string, threadId: string) : Promise<Maybe<SmsThreadTranslateOutgoing>> {
    return this.$apollo.mutate({
      mutation: showThreadTranslateOutgoing,
      variables: { body, threadId }
    }).then(result => result.data?.showThreadTranslateOutgoing)
  }

  previewReverseTranslation (threadId: string, translatedText: string) : Promise<Maybe<SmsThreadReverseTranslation>> {
    return this.$apollo.mutate({
      mutation: threadReverseTranslation,
      variables: { threadId, translatedText }
    }).then(result => result.data?.threadReverseTranslation)
  }

  changeTranslationLanguage (threadId: string, language: string) {
    return this.updateThread(threadId, { translatedLanguage: language })
  }

  updateThread (threadId: string, data: Record<string, any>) : Promise<Maybe<ThreadUpdate>> {
    // This is necessary to avoid conflicts with the mutation, since we have the object's props in snake_case and it expects them in camelCase.
    const objProps2camelCase = (obj: Record<string, any>) => Object.entries(obj).reduce((acc: Record<string, any>, [key, value]) => {
      const newKey = camelCase(key)
      acc[newKey] = value
      return acc
    }, {})
    return this.$apollo.mutate({
      mutation: threadUpdate,
      variables: { data: [objProps2camelCase(data)], threadId }
    }).then(result => result.data)
  }

  getAvailableLanguages (): Promise<Maybe<AvailableLanguage>[] | null> {
    return this.$apollo.query({
      query: getAvailableLanguages
    }).then(result => result.data.availableLanguagues)
  }
}
